<script>
export default {
  name: 'Progress',
  props: {
    currentVal: {
      default: 0,
      type: [Number, String],
    },
    percent: {
      default: 0,
      type: Number,
    },
    theme: {
      default: 'primaryGreen',
      type: String,
    },
    size: {
      default: 300,
      type: Number,
    },
    width: {
      default: 30,
      type: Number,
    },
    title: {
      default: 'Title',
      type: String,
    },
    preValue: {
      default: '',
      type: String,
    },
  },

  computed: {
    r() {
      return this.size / 2 - this.width / 2
    },
    c() {
      return 2 * 3.14 * this.r
    },
    piece() {
      return (1 - this.percent / 100) * this.c
    },
  },
}
</script>

<template>
  <div class="progressBar">
    <figure class="progressBarContainer">
      <svg
        v-if="size"
        :viewbox="[0, 0, size, size]"
        :width="size"
        :height="size"
        class="circleChart"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          :r="r"
          :cx="size / 2"
          :cy="size / 2"
          :class="['circleChartBackground', theme]"
          :stroke-width="width"
          fill="none"
        />
        <circle
          :r="r"
          :cx="size / 2"
          :cy="size / 2"
          :class="['circleChartSlide', theme]"
          :stroke-dasharray="c"
          :stroke-dashoffset="piece"
          :stroke-width="width"
          fill="none"
        />
      </svg>
      <div class="content">
        {{ preValue }}{{ currentVal }}
      </div>
    </figure>
    <h4 class="title">
      {{ title }}
    </h4>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.progressBarContainer
  position: relative
  text-align: center

.circleChart
  transform: rotateZ(-90deg)
  &Background
    stroke: $c-boulder-grey
  &Slide
    animation: dash 2s ease-out forwards
    &.primaryGreen
      stroke: $c-acc-green
    &.primaryBlue
      stroke: $c-acc-blue
    &.primaryRed
      stroke: $c-acc-red

.title
  margin-top: $base-spacing
  text-align: center

.content
  position: absolute
  top: 50%
  right: 0
  left: 0
  margin: 0 auto
  font-size: 16px
  font-weight: bold
  text-align: center
  transform: translateY(-50%)

@keyframes dash
  from
    stroke-dashoffset: 600
</style>

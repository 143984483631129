<script>
import CacheStatistics from './../../components/CacheStatistics'
import usersService from './../../services/users.service'
import postsService from './../../services/posts.service'

export default {
    components: {
        CacheStatistics,
    },
    data() {
        return {
            users: [],
            latestPosts: [],
            lastLoginFields: [
                {
                    name: 'photo',
                    label: this.$tc('photo'),
                    customElement: 'photo',
                },
                {
                    name: 'username',
                    label: this.$t('username'),
                },
                {
                    name: 'last_login',
                    label: `${this.$t('last')} ${this.$tc('login')}`,
                    format: date => new Date(date).toLocaleString('en-GB'),
                },
            ],
            latestPostFields: [
                {
                    name: 'published',
                    label: this.$t('published'),
                    customElement: 'published',
                },
                {
                    name: 'title',
                    label: this.$t('title'),
                    customElement: 'title',
                },
                {
                    name: 'changed_at',
                    label: this.$t('lastUpdated'),
                    format: date => new Date(date).toLocaleString('en-GB'),
                },
            ],
        }
    },
    computed: {
        author() {
            return this.$store.state.auth.authUser
        },
    },
    mounted() {
        this.i18n = this.$i18n
        this._getActiveUsers()
        this._getLatestPosts()
    },
    methods: {
        _getActiveUsers() {
            usersService.getAll().then(res => {
                this.users = res.data
                    .filter(user => user.published === 1)
                    .sort((a, b) => new Date(b.last_login) - new Date(a.last_login))
            })
        },
        _getLatestPosts() {
            this.author &&
                postsService.getAll({ limit: 5, author: this.author.username }).then(res => {
                    this.latestPosts = res.data.map(item => {
                        item.changed_at = item.updated_at || item.created_at
                        return item
                    })
                })
        },
    },
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <BaseCard>
          <div slot="header">
            <h5 class="card-category">
              {{ $tc('user', 2) }}
            </h5>
            <h2 class="card-title">
              {{ $t('latestLogin') }}
            </h2>
          </div>
          <div>
            <BaseListTable
              :items="users"
              :header-fields="lastLoginFields"
              type="users"
            />
          </div>
        </BaseCard>
      </div>
      <div class="col-md-6">
        <BaseCard>
          <div slot="header">
            <h5 class="card-category">
              {{ $tc('post', 2) }}
            </h5>
            <div class="row">
              <div class="col-6">
                <h2 class="card-title">
                  {{ $t('latest') }}
                </h2>
              </div>
              <div class="form-group col-6 text-right">
                <router-link :to="{ name: 'singlePost', params: { id: 'new' } }">
                  <BaseButton
                    type="green"
                    gradient
                  >
                    {{ $t('addItem', { item: $tc('post') }) }}
                  </BaseButton>
                </router-link>
              </div>
            </div>
          </div>
          <div>
            <BaseListTable
              :items="latestPosts"
              :header-fields="latestPostFields"
              type="posts"
            />
          </div>
        </BaseCard>
      </div>
    </div>
    <div
      v-if="$store.getters['permissions/checkIfUserHasPermission']('memcached.read-statistics')"
      class="row cache"
    >
      <div class="col-12">
        <CacheStatistics />
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.cache
    text-align: center
</style>

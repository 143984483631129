import { get, remove } from '@/services/api.service';

const API_URL = '/cache';

class MemcacheService {
  getStatistics(){
    return get(`${API_URL}/statistics?extended=1`)
    .then(res => res)
  }

  clearWholeCache() {
    return remove(`${API_URL}`)
      .then(res => res)
  }
}

export default new MemcacheService();
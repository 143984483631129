<script>
import Progress from '@/components/Progress'
import memcacheService from '@/services/memcache.service'

export default {
    components: {
        Progress,
    },
    data() {
        return {
            cacheStatistics: null,
            cacheTimeout: null
        }
    },
    mounted() {
        this._getMemcacheStatistics()
        clearInterval(this.cacheTimeout)
        this.cacheTimeout = setInterval(() => {
            this._getMemcacheStatistics()
        }, 5000)
    },
    destroyed() {
        clearInterval(this.cacheTimeout)
    },
    methods: {
        _getMemcacheStatistics() {
            memcacheService.getStatistics().then(res => {
                this.cacheStatistics = res.data
            })
        },
        clearWholeCache() {
            memcacheService.clearWholeCache().then(res => {
                this.$notify({
                    message: this.$t('m.cacheSuccessfullyCleared'),
                    icon: 'far fa-bell',
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success',
                })
            })
        },
    }
}
</script>

<template>
  <BaseCard>
    <Progress
      v-if="cacheStatistics"
      :current-val="`${cacheStatistics.used_memcached.toFixed(2)} / ${
        cacheStatistics.total_memcached
      } MB`"
      :percent="(cacheStatistics.used_memcached / cacheStatistics.total_memcached) * 100"
      :size="150"
      :width="15"
      :title="$t('cacheUsed')"
      theme="primaryBlue"
    />
    <BaseButton
      type="green"
      gradient
      @click="clearWholeCache"
    >
      {{ $t('clearWholeCache') }}
    </BaseButton>
  </BaseCard>
</template>

<style>
</style>
